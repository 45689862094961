import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    borderRadius: '8px',
    width: '408px',
    boxShadow: theme.palette.shadow.cardBoxShadow,
    '& a': {
      textDecoration: 'none',
      height: '100%',
    },
    '&:hover': {
      boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.28)',
    },
  },
  cardInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1.875rem',
    paddingRight: '1.875rem',
    paddingBottom: '1.875rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1.25rem',
      paddingLeft: '1.25rem',
      paddingRight: '1.25rem',
    },
  },
  imageContainer: {
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '18.75rem',
    objectFit: 'cover',
  },
  topLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  videoTag: {
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    border: '1px solid',
    paddingRight: '0.65rem',
    paddingLeft: '0.65rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    color: theme.palette.primary.main,
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    fontFamily: theme.typography.overline.fontFamily,
  },
  summary: {
    overflow: 'hidden',
    lineClamp: '2',
    display: '-webkit-box',
    'text-overflow': 'ellipsis',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  publishDate: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
  },
  headline: {
    marginBottom: '0.625rem',
    fontSize: theme.typography.subtitleXl.fontSize,
    lineHeight: theme.typography.subtitleXl.lineHeight,
    fontWeight: theme.typography.subtitleXl.fontWeight,
  },
  tagsRow: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    bottom: '1.275rem',
    paddingLeft: '1.875rem',
    paddingRight: '1.875rem',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      bottom: '0.75rem',
      paddingLeft: '1.25rem',
      paddingRight: '1.25rem',
    },
  },
  tagsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      gap: '6px',
    },
  },
  tag: {
    ...theme.typography.formTags,
    padding: '5px 10px',
    borderRadius: '6px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  videoIcon: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '6px',
  },

}))

export default useStyles
