/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import Link from '@components/link'
import { PublishDate, PublishDateCard, Image } from '@components/atoms'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useStyles from './card-featured-story.styles'
import { gtmEvent } from '@lib/gtm-utils'

const CardFeaturedStory = (props) => {
  const {
    headline,
    image,
    sys,
    video,
    summary,
    created_date,
    tagsCollection,
    className,
    cat,
  } = props
  const styles = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const firstPublishedAt = sys ? sys.firstPublishedAt : created_date
  return (
    <Box className={`${styles.root} ${className}`} onClick={() => gtmEvent({
      event: 'click-story-card',
      attributes: {
        title: headline,
        category: cat?.category || cat?.series
      }
    })}>
      <Link {...props}>
        <Box className={styles.imageContainer}>
          {image && <Image className={styles.image} src={`${image.url}?w=400&h=300&fit=fill`} alt={headline} />}
          <Box className={styles.tagsRow}>
            <Box className={styles.tagsWrapper}>
              {/* 2 tags max can be shown */}
              {tagsCollection && tagsCollection.items.slice(0, 2).map(item => (
                <Typography key={item.sys.id} variant="body2" className={styles.tag}>{item.label}</Typography>))}
            </Box>
          </Box>

        </Box>
        <Box className={styles.cardInfo}>
          <Box className={styles.topLine}>
            {firstPublishedAt && (
              <PublishDateCard className={styles.publishDate}>
                {!isMobile && 'Published'} <PublishDate publishedAt={firstPublishedAt} />
              </PublishDateCard>
            )}
            {video && <Typography className={styles.videoTag}>Video</Typography>}
          </Box>
          <Typography
            variant="body1"
            variantMapping={{ body1: 'h3' }}
            className={styles.headline}
            aria-hidden="true"
          >
            {headline}
          </Typography>
          <Typography variant="body2" className={styles.summary}>{summary}</Typography>
        </Box>
      </Link>
    </Box>
  )
}

CardFeaturedStory.propTypes = {
  __typename: PropTypes.string,
  cat: PropTypes.shape({
    category: PropTypes.string,
    series: PropTypes.string,
  }),
  created_date: PropTypes.string,
  headline: PropTypes.string,
  image: PropTypes.shape({
    description: PropTypes.string,
    url: PropTypes.string,
  }),
  url: PropTypes.string,
  slug: PropTypes.string,
  sys: PropTypes.shape({
    id: PropTypes.string,
    publishedAt: PropTypes.string,
    firstPublishedAt: PropTypes.string,
  }),
  video: PropTypes.shape(),
  summary: PropTypes.string,
  tagsCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  className: PropTypes.string,
}

CardFeaturedStory.defaultProps = {
  cat: {},
  className: '',
}

export default CardFeaturedStory
