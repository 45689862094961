import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  sliderRoot: {
    position: 'relative',
    width: '100%',
  },
  keenSlider: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignContent: 'flex-start',
    overflow: 'visible !important',
    touchAction: 'pan-y',
    userSelect: 'none',
    padding: '30px 0px',
    [theme.breakpoints.up('sm')]: {
      justifyContent: ({ centerSlider, disabledControls }) => (centerSlider && disabledControls ? 'center' : 'flex-start'),
    },
  },
  slide: {
    overflow: 'initial !important',
    width: 'initial !important',
  },
  card: {
    height: '100% !important',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  },
  slideScaler: {
    transformOrigin: '50% 50%',
    transition: 'scale .3s linear, height .3s linear',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      scale: '.8',
    },
  },
  slideScalerOpen: {
    [theme.breakpoints.down('xs')]: {
      scale: '1',
    },
  },
  disablePrev: {
    '& button:first-child': {
      opacity: '0.5',
      pointerEvents: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        outline: 'none',
      },
    },
  },
  disableNext: {
    '& button:last-child': {
      opacity: '0.5',
      pointerEvents: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        outline: 'none',
      },
    },
  },
}))

export default useStyles
