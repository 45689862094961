export const baseConfig = {
  initial: 0,
  loop: false,
  mode: 'snap',
  slides: { origin: 'center', perView: 1.3, spacing: 0 },
  breakpoints: {
    '(min-width: 600px)': {
      loop: false,
      slides: { perView: 'auto', spacing: 28 },
    },
  },
}

export const centerDesktopConfig = {
  ...baseConfig,
  breakpoints: {
    '(min-width: 600px)': {
      loop: false,
      slides: { perView: 'auto' },
    },
  },
}
